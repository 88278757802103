import { Icon, IconsEnum } from "@gravity/icons";
import { DecoratedFormControl, GvtButton, GvtIconButton, GvtInput, GvtModalDialog, SimpleTable, TagList, Toggle } from "@gravity/ui-components";
import _ from "lodash";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledColumn, StyledSimpleTable, StyledSimpleTableAdd, StyledToggle } from "../styled-components";
import { EnvironmentVariablesFieldEnum } from "../enums";

export const EnvironmentVariablesTable = forwardRef(({
  envVars = [],
  error = false,
  onChange = () => { },
  readOnly = false,
  disableEdit = false
}, ref) => {
  const { t } = useTranslation(['appCreation', "common"]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [defaultVal, setDefaultVal] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [validation, setValidation] = useState("");
  const [required, setRequired] = useState(false);
  const [supportedValues, setSupportedValues] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);

  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [validationError, setValidationError] = useState("");

  const tagsRef = useRef();

  useImperativeHandle(ref, () => ({
    validateEnvVars() {
      for (let i = 0; i < envVars.length; i++) {
        if (validateName(envVars[i][EnvironmentVariablesFieldEnum.name], true) != '' ||
          validateDescription(envVars[i][EnvironmentVariablesFieldEnum.description]) != '' ||
          validateValidation(envVars[i][EnvironmentVariablesFieldEnum.validation]) != '') {
          return false;
        }
      }
      return true;
    }
  }));

  const validateAll = () => {
    let arr = [validateName(name), validateDescription(description), validateValidation(validation)]
    arr = [...new Set(arr)];
    return !(arr.length > 1 || (arr.length == 1 && arr[0] != ''));
  }

  const changeName = (e) => {
    let value = e.target.value
    validateName(value);
    setName(value);
  }

  const validateName = (value, ignore_duplicated = false) => {
    let errorType = !value ? 'required' : '';
    
    if (errorType == '') {
      for (let i = 0; i < value.length; i++) {
        if (value.charCodeAt(i) > 127) {
          errorType = 'ascii_validation';
          break;
        }
      }
    }

    if (errorType == '' && !ignore_duplicated) {
      for (let i = 0; i < envVars.length; i++) {
        if (envVars[i].name == value && i != editIndex) {
          errorType = 'duplicated';
          break;
        }
      }
    }
    setNameError(errorType)
    return errorType;
  }

  const changeDescription = (e) => {
    let value = e.target.value;
    validateDescription(value);
    setDescription(value);
  }

  const validateDescription = (value) => {
    let errorType = !value ? 'required' : '';
    setDescriptionError(errorType)
    return errorType;
  }

  const changeDefaultVal = (e) => {
    let value = e.target.value;
    setDefaultVal(value);
  }

  const changePlaceholder = (e) => {
    let value = e.target.value;
    setPlaceholder(value);
  }

  const changeValidation = (e) => {
    let value = e.target.value;
    validateValidation(value);
    setValidation(value);
  }

  const validateValidation = (value) => {
    let errorType = '';
    try {
      new RegExp(value);
    } catch (e) {
      errorType = 'regex';
    }
    setValidationError(errorType)
    return errorType;
  }

  const changeRequired = (e) => {
    let value = e.target.checked;
    setRequired(value);
  }

  const changeSupportedValues = (values) => {
    setSupportedValues(values);
  }

  const getMessage = (key, value) => {
    if (value != '') {
      return t('fields.container_required_environment_variables.fields.' + key + '.error.' + value)
    }
    return ''
  }

  const openModal = () => {
    setDialogOpen(true);
    setName("");
    setDescription("");
    setDefaultVal("");
    setPlaceholder("");
    setValidation("");
    setRequired(false);
    setSupportedValues([]);
    clearError();
    setEditIndex(-1);
  }

  const clearError = () => {
    setNameError("");
    setDescriptionError("");
    setValidationError("");
  }

  const addEnvVar = (name, description, defaultVal, placeholder, validation, required, supportedValue) => {
    let envVarsList = _.cloneDeep(envVars);
    let duplicated = false
    let newConfig = {
      [EnvironmentVariablesFieldEnum.name]: name,
      [EnvironmentVariablesFieldEnum.description]: description,
      [EnvironmentVariablesFieldEnum.default]: defaultVal,
      [EnvironmentVariablesFieldEnum.placeholder]: placeholder,
      [EnvironmentVariablesFieldEnum.validation]: validation,
      [EnvironmentVariablesFieldEnum.required]: required,
      [EnvironmentVariablesFieldEnum.supported_values]: supportedValue,
    }
    if (editIndex == -1) {
      envVarsList.push(newConfig);
    } else {
      envVarsList[editIndex] = newConfig;
    }
    onChange(envVarsList);
    setDialogOpen(false);
  }

  const removeEnvVar = (value) => {
    let envVarsList = _.cloneDeep(envVars);
    for (let i = 0; i < envVarsList.length; i++) {
      if (_.isEqual(value, envVarsList[i])) {
        envVarsList.splice(i, 1);
        break;
      }
    }
    onChange(envVarsList);
  }

  const editEnvVar = (value) => {
    for (let i = 0; i < envVars.length; i++) {
      if (_.isEqual(value, envVars[i])) {
        setEditIndex(i);
        break;
      }
    }
    setDialogOpen(true);
    setName(value[EnvironmentVariablesFieldEnum.name]);
    setDescription(value[EnvironmentVariablesFieldEnum.description]);
    setDefaultVal(value[EnvironmentVariablesFieldEnum.default]);
    setPlaceholder(value[EnvironmentVariablesFieldEnum.placeholder]);
    setValidation(value[EnvironmentVariablesFieldEnum.validation]);
    setRequired(value[EnvironmentVariablesFieldEnum.required]);
    setSupportedValues(value[EnvironmentVariablesFieldEnum.supported_values]);
    clearError();
  }

  return (
    <>
      <StyledSimpleTable style={{ width: disableEdit ? "100%" : null, padding: disableEdit ? "0 1%" : null}}>
        {!readOnly && !disableEdit && envVars.length > 0 && (<StyledSimpleTableAdd>
          <GvtIconButton onClick={() => { openModal() }} disabled={dialogOpen}>
            <Icon name={IconsEnum.FormAdd} />
          </GvtIconButton>
        </StyledSimpleTableAdd>)}
        <div className={error ? (envVars.length <= 0 && !readOnly ? "has-error-self" : "has-error") : ""}>
          {envVars.length > 0 && (<SimpleTable columns={[
            {
              field: EnvironmentVariablesFieldEnum.name,
              displayName: t('fields.container_required_environment_variables.fields.name.label')
            },
            {
              field: EnvironmentVariablesFieldEnum.description,
              displayName: t('fields.container_required_environment_variables.fields.description.label')
            },
            {
              field: EnvironmentVariablesFieldEnum.default,
              displayName: t('fields.container_required_environment_variables.fields.default.label')
            },
            {
              field: EnvironmentVariablesFieldEnum.placeholder,
              displayName: t('fields.container_required_environment_variables.fields.placeholder.label')
            },
            {
              field: EnvironmentVariablesFieldEnum.validation,
              displayName: t('fields.container_required_environment_variables.fields.validation.label'),
              cellFormatter: (rowData, value) => {
                return (
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {value}
                    {!readOnly && !disableEdit && (
                      <div>
                        <GvtIconButton onClick={() => { editEnvVar(rowData) }}>
                          <Icon name={IconsEnum.FormEdit} />
                        </GvtIconButton>
                        <GvtIconButton onClick={() => { removeEnvVar(rowData) }}>
                          <Icon name={IconsEnum.FormTrash} />
                        </GvtIconButton>
                      </div>
                    )}
                  </div>
                )
              }
            }
          ]} rows={envVars} rowsRestriction={envVars.length + 1} />)}
          {envVars.length <= 0 && !readOnly && (<GvtButton color='secondary' onClick={() => openModal()} disabled={dialogOpen}>{t('fields.container_required_environment_variables.new.title')}</GvtButton>)}
          {envVars.length <= 0 && readOnly && (<div>{t('fields.container_required_environment_variables.no_data')}</div>)}
        </div>
      </StyledSimpleTable>
      <GvtModalDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onBackdropClick={() => setDialogOpen(false)}
        title={t('fields.container_required_environment_variables.new.title')}
        buttons={[
          {
            onClick: () => setDialogOpen(false),
            children: t('cancel', { ns: "common" })
          },
          {
            onClick: () => {
              if (validateAll()) {
                addEnvVar(name, description, defaultVal, placeholder, validation, required, supportedValues);
              }
            },
            color: 'primary',
            children: t('save', { ns: "common" })
          }
        ]}
      >
        <StyledColumn>
          <DecoratedFormControl required={true} label={t('fields.container_required_environment_variables.fields.name.label')} description={t('fields.container_required_environment_variables.fields.name.description')} error={nameError != ''} info={getMessage(EnvironmentVariablesFieldEnum.name, nameError)}>
            <GvtInput onBlur={(e) => { setName(e.target.value.toUpperCase()) }} onChange={changeName} value={name} />
          </DecoratedFormControl>
          <DecoratedFormControl required={true} label={t('fields.container_required_environment_variables.fields.description.label')} description={t('fields.container_required_environment_variables.fields.description.description')} error={descriptionError != ''} info={getMessage(EnvironmentVariablesFieldEnum.description, descriptionError)}>
            <GvtInput onChange={changeDescription} value={description} />
          </DecoratedFormControl>
          <DecoratedFormControl label={t('fields.container_required_environment_variables.fields.default.label')} description={t('fields.container_required_environment_variables.fields.default.description')} info={getMessage(EnvironmentVariablesFieldEnum.default, '')}>
            <GvtInput onChange={changeDefaultVal} value={defaultVal} />
          </DecoratedFormControl>
          <DecoratedFormControl label={t('fields.container_required_environment_variables.fields.placeholder.label')} description={t('fields.container_required_environment_variables.fields.placeholder.description')} info={getMessage(EnvironmentVariablesFieldEnum.placeholder, '')}>
            <GvtInput onChange={changePlaceholder} value={placeholder} />
          </DecoratedFormControl>
          <DecoratedFormControl label={t('fields.container_required_environment_variables.fields.validation.label')} description={t('fields.container_required_environment_variables.fields.validation.description')} error={validationError != ''} info={getMessage(EnvironmentVariablesFieldEnum.validation, validationError)}>
            <GvtInput onChange={changeValidation} value={validation} />
          </DecoratedFormControl>
          <DecoratedFormControl description={t('fields.container_required_environment_variables.fields.required.description')}>
            <StyledToggle>
              <Toggle label={t('fields.container_required_environment_variables.fields.required.label')} onChange={changeRequired} checked={required} info={getMessage(EnvironmentVariablesFieldEnum.required)} />
            </StyledToggle>
          </DecoratedFormControl>
          <TagList defaultValue={supportedValues} onChange={changeSupportedValues} editor={{
            props: {
              inputProps: {
                label: t('fields.container_required_environment_variables.fields.supported_values.label'), 
                description: t('fields.container_required_environment_variables.fields.supported_values.description'), 
                info: t('fields.container_required_environment_variables.fields.supported_values.help')
              },
              validate: (tag) => { return !supportedValues.includes(tag) }
            }
          }} />
        </StyledColumn>
      </GvtModalDialog>
    </>
  )
})

export default EnvironmentVariablesTable;